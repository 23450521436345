import { useEffect } from 'react';
import { AuthenticationProvider } from '../auth/AuthenticationProvider';

function useTokenRefresh(authProvider: AuthenticationProvider) {
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await authProvider.refreshToken();
      } catch (error) {
        console.error('Failed to refresh token', error);
        alert("You're no longer logged in, please refresh the page."); // shouldn't happen, but just in case
      }
    }, 5 * 60 * 1000); // Refresh token every 5 minutes

    return () => clearInterval(interval);
  }, [authProvider]);
}

export default useTokenRefresh;
