import { IStudentCourseCompetencyForThinkificModel } from '../domain/store/StudentStore';
import { useLocation } from 'react-router-dom';

export const useAvailableWeeks = (
  studentCompetencies: IStudentCourseCompetencyForThinkificModel[]
) => {
  const location = useLocation();
  const queryStringParams = new URLSearchParams(location.search);

  const email = queryStringParams.get('email');

  if (email !== 'julaytest@gmail.com' && email !== 'mhawcroft@gmail.com') {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  }

  if (!studentCompetencies || studentCompetencies.length === 0) return [0, 1, 2];

  let studentLastCompletedWeek: number | null = null;

  for (let i = 0; i < studentCompetencies.length; i++) {
    const competency = studentCompetencies[i];

    const isCompleted = competency?.status === 'C';

    if (isCompleted) {
      if (
        competency.competencyTitle.includes('Warm Up') ||
        competency.competencyTitle.includes('Warmup')
      ) {
        studentLastCompletedWeek = 0;
      } else if (competency.competencyTitle.includes('Week')) {
        const match = competency.competencyTitle.match(/Week (\d{1,2})/);

        if (match) {
          const week = parseInt(match[1]);
          studentLastCompletedWeek = week;
        }
      }
    } else {
      break;
    }
  }

  let weeks: number[] = [];

  if (studentLastCompletedWeek === null || studentLastCompletedWeek === 0) {
    weeks = [0, 1, 2];
  } else {
    const weekCanBookUpTo = Math.min(20, studentLastCompletedWeek + 2);
    weeks = Array.from({ length: weekCanBookUpTo + 1 }, (_, i) => i);
  }

  return weeks;
};
