import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';
import { CourseType, IntakeGradeBookItemModel } from './OnlineCoursesModel';

export const StudentContactModel = types.model('StudentContactModel', {
  id: types.identifierNumber,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  phone2: types.maybeNull(types.string),
  email2: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  timezone: types.maybeNull(types.string),
  personType: types.maybeNull(types.string),
  publicId: types.maybeNull(types.string),
  salesOwnerId: types.maybeNull(types.number),
  lastRefreshDateTime: types.maybeNull(types.string),
});

export const StudentEnrolmentGradeBookItemModel = types.model(
  'StudentEnrolmentGradeBookItemModel',
  {
    id: types.identifierNumber,
    created: types.string,
    url: types.string,
    completionPercentage: types.number,
    itemId: types.number,
    itemName: types.string,
    itemSection: types.string,
    itemType: types.string,
  }
);

export const StudentEnrolmentIntakeModel = types.model('StudentEnrolmentIntakeModel', {
  id: types.identifierNumber,
  title: types.string,
  publicIdentifier: types.string,
  courseDeadline: types.maybeNull(types.string),
  courseType: types.string,
  acuityCategory: types.string,
  elearningAudience: types.string,
  elearningAudienceSecondary: types.maybeNull(types.string),
  elearningPrimaryCourseIds: types.maybeNull(types.string),
  elearningSecondaryCourseIds: types.maybeNull(types.string),
});

export const StudentCourseEnrolmentModel = types.model('StudentEnrolmentModel', {
  id: types.identifierNumber,
  courseTitle: types.string,
  intakeId: types.maybeNull(types.number),
  moduleId: types.maybeNull(types.number),
  lmsCourseId: types.number,
  gradebookForStudent: types.array(StudentEnrolmentGradeBookItemModel),
  graduationCriteriaMetTimestamp: types.maybeNull(types.string),
  graduationEligibilityLastCheckedTimestamp: types.maybeNull(types.string),
  gradebookCompletionTimeStamp: types.maybeNull(types.string),
  gradebookLastSyncedTimeStamp: types.maybeNull(types.string),
  elearningAudience: types.string,
  lms: types.string,
});

export const StudentNoteModel = types.model('StudentNoteModel', {
  id: types.identifierNumber,
  studentId: types.number,
  isCritical: types.boolean,
  type: types.string,
  source: types.string,
  shortContent: types.string,
  detailedContent: types.string,
  intakeId: types.maybeNull(types.number),
  createdTimestamp: types.string,
  createdBy: types.string,
  deletedTimestamp: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
});

export interface IStudentAddNote {
  studentId: number;
  isCritical: boolean;
  type: string;
  source: string;
  shortContent: string;
  detailedContent: string;
  intakeId: number | null;
}

export const StudentIntakeEnrolmentModel = types
  .model('', {
    intake: types.maybeNull(StudentEnrolmentIntakeModel),
    courseEnrolments: types.array(StudentCourseEnrolmentModel),
    graduationCriteriaMetTimestamp: types.maybeNull(types.string),
    graduationEligibilityLastCheckedTimestamp: types.maybeNull(types.string),
    gradebookCompletionTimeStamp: types.maybeNull(types.string),
    gradebookLastSyncedTimeStamp: types.maybeNull(types.string),
    enrolmentFirstDetectedTimestamp: types.maybeNull(types.string),
    enrolmentLastDetectedTimestamp: types.maybeNull(types.string),
    logbookCompletionTimestamp: types.maybeNull(types.string),
    logbookLastCheckedTimestamp: types.maybeNull(types.string),
    enrolmentNoLongerDetectedEarliestTimestamp: types.maybeNull(types.string),
    elearningAudience: types.array(types.string),
    intakeCompletionPercentage: types.number,
    gradebookItemsForIntake: types.array(IntakeGradeBookItemModel),
  })
  .views((self) => ({
    hasLogbook: () => {
      return self.intake?.courseType === CourseType.Certificate;
    },
  }));

export const StudentTagModel = types.model('StudentTagModel', {
  id: types.identifierNumber,
  email: types.string,
  tagId: types.number,
  createdTimestamp: types.string,
  createdBy: types.string,
  deletedTimestamp: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  lmsStudentTableId: types.number,
});

export const StudentCustomFieldModel = types.model('StudentCustomFieldModel', {
  id: types.identifierNumber,
  email: types.string,
  fieldId: types.number,
  fieldValue: types.string,
  createdTimestamp: types.string,
  createdBy: types.string,
  lmsStudentTableId: types.number,
});

export const StudentModel = types.model('StudentModel', {
  id: types.identifierNumber,
  contact: types.maybeNull(StudentContactModel),
  email: types.string,
  lmsId_totara: types.maybeNull(types.number),
  lmsId_thinkific: types.maybeNull(types.number),
  lastLoggedIn: types.maybeNull(types.string),
  intakeEnrolments: types.array(StudentIntakeEnrolmentModel),
  notes: types.array(StudentNoteModel),
  tags: types.array(StudentTagModel),
  customFields: types.array(StudentCustomFieldModel),
});

const CourseCompetencyTemplateModel = types.model('CourseCompetencyTemplateModel', {
  id: types.number,
  type: types.string,
  title: types.string,
  emailSubject: types.string,
  emailContent: types.string,
  createdBy: types.maybeNull(types.string),
  createdOn: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  deletedOn: types.maybeNull(types.string),
});

const CourseCompetencyModel = types.model('CourseCompetencyModel', {
  id: types.number,
  lmsCourseId: types.number,
  lms: types.string,
  competencyTitle: types.string,
  competencyDetails: types.string,
  competencyType: types.string,
  displayOrder: types.number,
  countTypeRequirement: types.maybeNull(types.number),
  group1: types.maybeNull(types.string),
  templates: types.array(CourseCompetencyTemplateModel),
});

const StudentCourseCompetencyModel = types.model('StudentCourseCompetencyModel', {
  id: types.number,
  courseCompetencyId: types.number,
  courseEnrolmentId: types.number,
  studentId: types.number,
  studentEmail: types.string,
  status: types.string,
  note: types.string,
  countTypeValue: types.maybeNull(types.number),
  feedbackSent: types.string,
});

const StudentCourseCompetencyForThinkificModel = types.model(
  'StudentCourseCompetencyForThinkificModel',
  {
    courseCompetencyId: types.number,
    competencyTitle: types.string,
    competencyDetails: types.string,
    studentId: types.number,
    status: types.string,
    competencyType: types.string,
    countTypeRequirement: types.maybeNull(types.number),
    displayOrder: types.maybeNull(types.number),
    group1: types.maybeNull(types.string),
    countTypeValue: types.maybeNull(types.number),
    feedbackSent: types.string,
  }
);

const CourseModel = types.model('CourseModel', {
  id: types.number,
  name: types.string,
  slug: types.string,
  subtitle: types.string,
  product_id: types.number,
  description: types.string,
  course_card_text: types.string,
  intro_video_youtube: types.string,
  contact_information: types.string,
  keywords: types.string,
  duration: types.string,
  banner_image_url: types.string,
  course_card_image_url: types.string,
  intro_video_wistia_identifier: types.string,
  administrator_user_ids: types.array(types.number),
  chapter_ids: types.array(types.number),
  reviews_enabled: types.boolean,
  user_id: types.maybeNull(types.number),
  instructor_id: types.number,
});

const StudentThinkificCourseEnrolmentModel = types.model('StudentThinkificCourseEnrolmentModel', {
  studentId: types.number,
  thinkificStudentId: types.number,
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  enrollmentId: types.number,
});

export interface IStudentModel extends Instance<typeof StudentModel> {}

export interface IStudentCourseEnrolmentModel
  extends Instance<typeof StudentCourseEnrolmentModel> {}

export interface IStudentEnrolmentGradeBookItemModel
  extends Instance<typeof StudentEnrolmentGradeBookItemModel> {}

export interface IStudentContactModel extends Instance<typeof StudentContactModel> {}

export interface IStudentNoteModel extends Instance<typeof StudentNoteModel> {}

export interface IStudentTagModel extends Instance<typeof StudentTagModel> {}

export interface ICourseModel extends Instance<typeof CourseModel> {}
export interface IStudentCourseCompetencyModel
  extends Instance<typeof StudentCourseCompetencyModel> {}

export interface ICourseCompetencyModel extends Instance<typeof CourseCompetencyModel> {
  templates: any;
}

export interface IStudentThinkificCourseEnrolmentModel
  extends Instance<typeof StudentThinkificCourseEnrolmentModel> {}

export interface ICourseCompetencyTemplateModel
  extends Instance<typeof CourseCompetencyTemplateModel> {}

export interface IStudentCourseCompetencyForThinkificModel
  extends Instance<typeof StudentCourseCompetencyForThinkificModel> {}

export const StudentStore = types
  .model('StudentStore', {
    student: types.maybeNull(StudentModel),
  })
  .actions((self) => ({
    loadStudent: flow(function* (email: string) {
      const { ajax } = getEnv(self);

      self.student = null;

      try {
        self.student = yield ajax
          .get(`ProtectedStudent/${email}`)
          .json<IStudentModel>()
          .then((value) => StudentModel.create(value));

        self.student?.notes.sort((a, b) => {
          return new Date(b.createdTimestamp).getTime() - new Date(a.createdTimestamp).getTime();
        });
      } catch (error) {
        console.log(`error ProtectedStudent/${email} error: ${error}`);
      }
    }),
    loadPublicStudentGradebook: flow(function* (courseType: string, email: string) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(`PublicStudent/gradebook?courseType=${courseType}&email=${email}`)
          .json<IStudentEnrolmentGradeBookItemModel>();
      } catch (error) {
        console.log(`error PublicStudent/${email}/${courseType}/gradebook error: ${error}`);
      }
    }),
    addStudentNote: flow(function* (newNote: IStudentAddNote) {
      const { ajax } = getEnv(self);
      try {
        var note = yield ajax
          .post(`ProtectedStudent/notes`, { json: newNote })
          .json<IStudentAddNote>();

        self.student?.notes.push(note);

        self.student?.notes.sort((a, b) => {
          return new Date(b.createdTimestamp).getTime() - new Date(a.createdTimestamp).getTime();
        });

        return note.id;
      } catch (error) {
        console.log(`error StudentNote error: ${error}`);
      }
    }),
    addStudentTag: flow(function* (tagId: number) {
      const { ajax } = getEnv(self);
      try {
        var tag = yield ajax
          .post(`ProtectedStudent/tags`, { json: { email: self.student?.email, tagId: tagId } })
          .json<IStudentTagModel>();

        self.student?.tags.push(tag);

        return tag;
      } catch (error) {
        console.log(`error POST ProtectedStudent/tags error: ${error}`);
      }
    }),
    deleteStudentTag: flow(function* (tagId: number) {
      const { ajax } = getEnv(self);
      try {
        var tag = yield ajax
          .delete(`ProtectedStudent/tags/` + self.student?.email + '/' + tagId)
          .json<boolean>();

        if (self.student && self.student.tags) {
          if (self.student && self.student.tags) {
            const tagIndex = self.student.tags.findIndex((t) => t.id === tagId);
            if (tagIndex !== -1) {
              self.student.tags.splice(tagIndex, 1);
            }
          }
        }
        return tag;
      } catch (error) {
        console.log(`error POST ProtectedStudent/tags error: ${error}`);
      }
    }),
    getCourses: flow(function* () {
      const { ajax } = getEnv(self);
      try {
        return yield ajax.get(`ProtectedCompetency/courses`).json<ICourseModel>();
      } catch (error) {
        console.log(`error ProtectedCompetency/courses error: ${error}`);
      }
    }),
    getCompetenciesForCourse: flow(function* (courseId: number) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(`ProtectedCompetency/course/${courseId}/competencies`)
          .json<ICourseCompetencyModel[]>();
      } catch (error) {
        console.log(`error ProtectedCompetency/course/${courseId}/competencies error: ${error}`);
      }
    }),
    upsertCourseCompetency: flow(function* (courseCompetency: ICourseCompetencyModel) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .post(`ProtectedCompetency/courses/competencies`, { json: { item: courseCompetency } })
          .json<ICourseCompetencyModel>();
      } catch (error) {
        console.log(`error ProtectedCompetency/courses/competencies error: ${error}`);
      }
    }),
    deleteCourseCompetency: flow(function* (courseCompetencyId: number) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .delete(`ProtectedCompetency/courses/competencies/${courseCompetencyId}`)
          .json<boolean>();
      } catch (error) {
        console.log(
          `error ProtectedCompetency/courses/competencies/${courseCompetencyId} error: ${error}`
        );
      }
    }),
    getStudentCourseCompetencies: flow(function* (studentId: number, lmsCourseId: number) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(`ProtectedCompetency/courses/${lmsCourseId}/students/${studentId}/competencies`)
          .json<IStudentCourseCompetencyModel>();
      } catch (error) {
        console.log(
          `error ProtectedCompetency/courses/${lmsCourseId}/students/${studentId}/competencies error: ${error}`
        );
      }
    }),
    getStudentCourseCompetenciesByEmail: flow(function* (
      studentEmail: string,
      lmsCourseId: number
    ) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(
            `PublicStudent/student/coursecompetencies/${lmsCourseId}?studentEmail=${studentEmail}`
          )
          .json<IStudentCourseCompetencyForThinkificModel[]>();
      } catch (error) {
        console.log(
          `error PublicStudent/student/${studentEmail}/coursecompetencies/${lmsCourseId} error: ${error}`
        );
      }
    }),
    getCourseCompetenciesForStudents: flow(function* (studentIds: number[], lmsCourseId: number) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .post(`ProtectedCompetency/courses/studentscompetencies`, {
            json: { studentIdFilter: studentIds, lmsCourseId: lmsCourseId },
          })
          .json<IStudentCourseCompetencyModel[]>();
      } catch (error) {
        console.log(`error POST ProtectedCompetency/courses/students/competencies error: ${error}`);
      }
    }),
    upsertStudentCourseCompetency: flow(function* (
      studentCourseCompetency: IStudentCourseCompetencyModel
    ) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .post(`ProtectedCompetency/courses/students/competencies`, {
            json: studentCourseCompetency,
          })
          .json<IStudentCourseCompetencyModel>();
      } catch (error) {
        console.log(`error ProtectedCompetency/courses/competencies error: ${error}`);
      }
    }),
    deleteStudentCourseCompetency: flow(function* (studentCourseCompetencyId: number) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .delete(`ProtectedCompetency/courses/students/competencies/${studentCourseCompetencyId}`)
          .json<boolean>();
      } catch (error) {
        console.log(
          `error ProtectedCompetency/courses/students/competencies/${studentCourseCompetencyId} error: ${error}`
        );
      }
    }),
    getEnrollmentsForCourse: flow(function* (courseId: number) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(`ProtectedCompetency/courses/${courseId}/enrollments`)
          .json<IStudentThinkificCourseEnrolmentModel[]>();
      } catch (error) {
        console.log(`error ProtectedCompetency/course/${courseId}/enrollments error: ${error}`);
      }
    }),
    sendFeedbackEmail: flow(function* (
      studentId: number,
      courseCompetencyId: number,
      subject: string,
      body: string
    ) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .post(`ProtectedCompetency/feedback`, {
            json: { studentId, courseCompetencyId, subject, body },
          })
          .json<IStudentCourseCompetencyModel>();
      } catch (error) {
        console.log(`error ProtectedCompetency/feedback error: ${error}`);
      }
    }),
  }))
  .views((self) => ({}));
